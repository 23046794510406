import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'profile-tabs',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Profilo</h1>
      <h2 class="tx-subtitle mb-0">Modifica</h2>
    </ng-template>
    <ng-template header-menu>
      <fullstack-tabs>
        <tab [label]="'Dettaglio'" [url]="'./main-info'" *permissions="['is_admin']"></tab>
        <tab [label]="'Dati aggiuntivi'" [url]="'./additional-info'"></tab>
        <tab [label]="'Trasferimenti'" [url]="'./transfers'"></tab>
      </fullstack-tabs>
    </ng-template>
    <div class="p-4">
      <router-outlet></router-outlet>
    </div>
  `,
})
export class ProfileTabsComponent implements OnInit {
  public id: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
    });
  }
}
