import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { IApplication, IUser } from '@fi/app/core';
import { AuthServices } from '@fi/app/core/services/auth.services';
import { Observable } from 'rxjs';

@Component({
  selector: 'facile-profile',
  template: `
    <form [formGroup]="form" novalidate>
    <div class="rounded-sm border border-stroke bg-white shadow-default p-4"  *permissions="['is_admin']">

        <fullstack-input-user formControlName="user"></fullstack-input-user>

        <div class="row mt-5">
          <div class="col-12">
            <header class="page-header border-bottom-0">
              <h2 class="tx-title-3">Stato registrazione calendario</h2>
            </header>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <button *ngIf="!user?.data?.avanoaId" type="button" class="btn btn-secondary rounded">
              Calendar scollegato clicca qui per collegare
            </button>
            <div *ngIf="user?.data?.avanoaId" class="alert alert-primary" role="alert">
              Calendar collegato correttamente: {{ user?.data?.avanoaId }}
              <button type="button" class="btn btn-secondary rounded m-2">Scollega calendario</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  `,
})
export class ProfileComponent implements OnInit {
  form = this.fb.group({
    user: new UntypedFormControl(null, []),
  });

  applications$: Observable<IApplication[]>;
  user: IUser;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthServices,
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.authService.user().subscribe((user) => {
      this.form.patchValue({ user: user });
      this.user = user;
    });
  }
}
